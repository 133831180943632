.panel-style {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  pointer-events: none;
}
.panel-style input {
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  background: none;
  overflow: hidden;
  pointer-events: auto;
}
.panel-style svg {
  font-size: 2rem;
}
.panel-style .lineWidth {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-right: 0.5rem;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  text-align: center;
  text-align-last: center;
  cursor: pointer;
  pointer-events: auto;
  color: #fff;
  background: #000;
  outline: 0;
  border: 0;
  border-radius: 40%;
  overflow: hidden;
}
.function-item {
  margin-right: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  pointer-events: auto;
  border-radius: 50%;
}
.function-item-active {
  color: gray;
  filter: drop-shadow(0 0 2px gray);
}
.color-item {
  margin: 0 0.3rem;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
}
.color-item-pink {
  background: #ff69b4;
  color: #ff69b4;
}
.color-item-yellow {
  background: #ffff00;
  color: #ffff00;
}
.color-item-red {
  color: #ff0000;
  background: #ff0000;
}
.color-item-green {
  color: #00ff00;
  background: #00ff00;
}
.color-item-blue {
  color: #0000ff;
  background: #0000ff;
}
.color-item-rainbow {
  position: relative;
  background: conic-gradient(#9ed110, #50b517, #179067, #476eaf, #9f49ac, #cc42a2, #ff3ba7, #ff5800, #ff8100, #feac00, #ffcc00, #ede604, #9ed110);
}
.color-item-rainbow-active {
  position: relative;
}
.color-item-rainbow-active::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  filter: blur(2px) brightness(80%) opacity(0.8);
  border: none;
  border-radius: 50%;
  transform: scale(1.2);
  z-index: -1;
}
.color-item-active {
  box-shadow: 0 0 15px currentColor;
}
